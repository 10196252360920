<template>
<!--  TODO: Make this as accident-incident Master-list/Index-->
  <div class="text-center">
    <div class="row no-gutters justify-content-between align-items-center">
      <div class="col-sm-auto">
        <h3 class="mb-0 text-left">Accident / Incident Report List</h3>
      </div>
      <div class="col-sm-auto">
        <div class="row no-gutters justify-content-end my-2">
          <div class="col-sm-auto px-2">
            <button v-if="isAllowed('accident-incident.create')" class="e-btn e-btn-green force-white-all pr-3"
                    data-toggle="modal" data-target="#NewAccidentIncident">
              <font-awesome-icon icon="plus-square" style="width: 25px" />
              <small class="font-weight-bold px-2">
                NEW ACCIDENT / INCIDENT REPORT
              </small>
            </button>
          </div>
          <div class="col-sm-auto px-2">
            <input type="file" ref="import_air" style="display:none" @change.prevent="submitImportAIR" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
            <button v-if="isAllowed('accident-incident.import')" class="e-btn e-btn-blue force-white-all pr-3" @click="clickImportAIR">
              <font-awesome-icon icon="plus-square" style="width: 25px" />
              <small class="font-weight-bold">
                IMPORT
              </small>
            </button>
          </div>
          <div class="col-sm-auto px-2">
            <template v-if="!toExport">
              <button class="e-btn e-btn-blue-gray force-white-all pr-3" @click="toExport=!toExport">
                <font-awesome-icon icon="file-excel" style="width: 25px" />
                <small class="font-weight-bold">
                  ACCIDENT INCIDENT BLANK TEMPLATE
                </small>
              </button>
            </template>
            <template v-else>
              <select  class="text-uppercase form-control form-control-sm" id="ExportAIRVesselList" v-model="exportVesselId" required @change="exportBlankTemplate">
                <option :value="null">—— Select Vessel ——</option>
                <option v-for="vessel in vesselKeys"
                        :key="vessel.id"
                        :value="vessel.id">
                  {{ vessel.name }}
                </option>
              </select>
            </template>
          </div>
        </div>
      </div>
    </div>
<!--    todo: comment for billing purposes -->
<!--    <div class="row mb-3 no-gutters justify-content-between align-items-center">-->
<!--      <div class="col-lg-12 ">-->
<!--        <div class="row no-gutters align-items-center">-->
<!--          <div class="col-md-auto">-->
<!--            <label class="col-form-label pr-2 font-weight-bold">Filter By: </label>-->
<!--          </div>-->
<!--          <div class="col-md-auto px-1">-->
<!--            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs" v-model="filters.fleet_id">-->
<!--              <option :value="null">&#45;&#45; ALL FLEET &#45;&#45;</option>-->
<!--              <option :value="fleet.id" v-for="fleet in fleetKeys">{{fleet.name ? fleet.name.toUpperCase() : ''}}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div class="col-md-auto px-1">-->
<!--            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs" v-model="filters.flag_id">-->
<!--              <option :value="null">&#45;&#45; ALL FLAG &#45;&#45;</option>-->
<!--              <option :value="country.id" v-for="country in countryKeys">{{country.name ? country.name.toUpperCase() : ''}}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div class="col-md-auto px-1">-->
<!--            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs" v-model="filters.company_id">-->
<!--              <option :value="null">&#45;&#45; ALL COMPANY &#45;&#45;</option>-->
<!--              <option :value="company.id" v-for="company in mngmtCompanyKeys">{{company.name ? company.name.toUpperCase() : ''}}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div class="col-md-auto px-1">-->
<!--            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs" v-model="filters.office_id">-->
<!--              <option :value="null">&#45;&#45; ALL MANAGER &#45;&#45;</option>-->
<!--              <option :value="office.id" v-for="office in mngmntOfficeKeys">{{office.name ? office.name.toUpperCase() : ''}}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div class="col-md-auto px-1">-->
<!--            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs" v-model="filters.status_id">-->
<!--              <option :value="null">&#45;&#45; ALL STATUS &#45;&#45;</option>-->
<!--              <option :value="status.id" v-for="status in reportStatusKeys">{{status.name ? status.name.toUpperCase() : ''}}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--    todo end: comment for billing purposes -->

    <div class="row no-gutters align-items-center justify-content-between">
      <div class="col-sm-auto pb-2 mr-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-sm-auto">
        <div class="row mb-3 no-gutters justify-content-between align-items-center">
          <div class="col-md-auto">
            <label class="col-form-label pr-2 font-weight-bold">Filter By: </label>
          </div>
          <div class="col-md-auto px-1">
            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs" v-model="filters.fleet_id">
              <option :value="null">-- ALL FLEET --</option>
              <template v-for="fleet in fleetKeys">
                <option :value="fleet.id" v-if="fleet.name !== 'N/A' && fleet.code !== 'N/A'">
                  {{fleet.name ? fleet.name.toUpperCase() : ''}}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-auto pb-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <label class="input-group-text input-group-sm bg-white small border-0" for="rowCount"># of Row(s):</label>
          </div>
          <select class="form-control form-control-sm rounded-left" v-model="filters.count" id="rowCount">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="10000">All</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row no-gutters" style="height: 75vh; overflow-y: auto">
      <div class="col-12">
        <table class="small e-tbl ">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 1em" scope="col">#</th>
            <th class="align-middle" scope="col">VESSEL</th>
            <th class="align-middle" scope="col">MANAGER</th>
            <th class="align-middle" scope="col">EVENT CODE 1</th>
            <th class="align-middle" style="min-width: 25em" scope="col">REPORT TITLE</th>
            <th class="align-middle" style="width: 5em" scope="col">STATUS</th>
            <th class="align-middle" style="min-width: 9em" scope="col">EVENT DATE</th>
            <th class="align-middle" style="width: 4em" scope="col">EVENT CLASS</th>
            <th class="align-middle" style="min-width: 6em" scope="col">DELAY (HRS.)</th>
            <!--        <th class="align-middle" scope="col">LOCATION</th>-->
            <th class="align-middle" style="width: 12em" scope="col">RESPONSIBLE</th>
            <th class="align-middle" style="width: 12em" scope="col">PERSON IN CHARGE</th>
            <th class="align-middle" style="max-width: 15em;" scope="col">Monthly Safety Protection Committee Status</th>
            <th class="align-middle" style="max-width: 15em;" scope="col">CREATED BY</th>
            <th class="align-middle" style="width: 70px" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <template v-else>
            <template v-if = "finalizedReports.length === 0">
              <tr>
                <td colspan="100%" class="text-center py-3">
                  No records found.
                </td>
              </tr>
            </template>
            <template v-else v-for="(report, index) in finalizedReports">
              <tr class="text-uppercase" :key="report.id">
                <td scope="row">{{ index + pagination.from }}</td>

                <td class="align-middle" style="white-space: nowrap;padding:0">{{ report.vessel }}</td>
                <td class="align-middle" style="white-space: nowrap;padding:0">{{ report.management_office }}</td>
                <td class="align-middle" v-if="report.event.event_one !== null" :class="['bg-' + eventOneColorCode[report.event.event_one.code].color_code]">
                  {{ report.event.event_one.name }}
                </td>
                <td class="align-middle" v-else>
                </td>
                <td class="align-middle">{{ report.title }}</td>
                <td class="align-middle text-white" :class="['bg-'+report.report_status_color]">{{ report.report_status ? report.report_status : 'NEW' }}</td>
                <td class="align-middle">{{ report.event_date | moment('D MMM YYYY') }}</td>
                <td class="align-middle"
                    :class="{
                                'eventClass_A': report.event_class === 'A',
                                'eventClass_B': report.event_class === 'B',
                                'eventClass_C': report.event_class === 'C'
                              }">
                  {{ report.event_class }}
                </td>
                <td class="align-middle">{{ report.totalDelayHrs }}</td>
                <td class="align-middle">{{report.responsible_person}}</td>
                <td class="align-middle">{{report.person_in_charge}}</td>
                <td class="align-middle" style="font-size: 9px">
                  KOBE - <strong :class="[report.kobe_status_color]">{{ report.kobe_status }}</strong> <br>
                  ROHQ - <strong :class="[report.rohq_status_color]">{{ report.rohq_status }}</strong> <br>
                  STGT - <strong :class="[report.stgt_status_color]">{{ report.stgt_status }}</strong> <br>
                </td>
                <td class="align-middle">{{ report.created_by?.name? report.created_by.name : '' }}</td>
                <td class="align-middle text-center">
                  <div class="dropdown dropright my-1">
                    <button
                      id="actionDropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-sm btn-secondary force-white-all"
                      data-toggle="dropdown"
                    >
                      <font-awesome-icon icon="cogs"/>
                    </button>
                    <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              @click="showReport(report.id)"
                      >
                        <font-awesome-icon icon="eye"/>
                        View
                      </button>
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              @click="showReportNewTab(report.id)"
                      >
                        <font-awesome-icon icon="share"/>
                        View on New Tab
                      </button>
                      <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                              @click="deleteSelected(report.id)"
                              v-if="isAllowed('accident-incident.delete') && ((report.report_status_id !== 1 && user.roles_permissions.roles[0] !== 'superintendent' && user.is_vessel === false) || (report.report_status_id === 1 && report.created_by_id == user.id)) "
                      >
                        <font-awesome-icon icon="trash" class="force-danger-all"/>
                        Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <new-accident-incident-report />
    <event-class-help-menu-modal modalId="eventClassDescription"/>
  </div>
</template>

<script>
import NewAccidentIncidentReport from '../layouts/NewAccidentIncidentReport'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import AppLoading from "@/components/elements/AppLoading";
import { PaginationMixin } from "@/mixins/PaginationMixin";
import {AlertService} from "@/services/AlertService";
import {ReportService} from "@/services/ReportService";
import EventClassHelpMenuModal from "@/components/EventClassHelpMenuModal.vue";
const {mapGetters,mapActions} = require('vuex')
export default {
  name: 'ReportIndex',
  data() {
    return {
      filters: {
        page: 1,
        count: 30,
        fleet_id:null,
        status_id:null,
        office_id:null,
        flag_id:null,
        company_id:null,
      },
      exportVesselId: null,
      toExport: false,
      loading: false,
      eventOneColorCode : {
        a : { // 1
          color      : '#0000ff',
          color_code : '0000ff'
        },
        b : { // 2
          color      : '#0000ff',
          color_code : '0000ff'
        },
        c : { // 3
          color      : '#ff0000',
          color_code : 'ff0000'
        },
        d : { // 4
          color      : '#ffbf00',
          color_code : 'ffbf00'
        },
        e : { // 5
          color      : '#c75912',
          color_code : 'c75912'
        },
        f : { // 6
          color      : '#c75912',
          color_code : 'c75912'
        },
        g : { // 7
          color      : '#7030a0',
          color_code : '7030a0'
        },
        h : { // 8
          color      : '#7030a0',
          color_code : '7030a0'
        },
        i : { // 9
          color      : '#548235',
          color_code : '548235'
        },
        j : { // 10
          color      : '#ff0066',
          color_code : 'ff0066'
        },
        k : { // 11
          color      : '#2f5496',
          color_code : '2f5496'
        },
        l : { // 12
          color      : '#2f5496',
          color_code : '2f5496'
        },
        m : { // 13
          color      : '#a6a6a6',
          color_code : 'a6a6a6'
        }
      }
    }
  },
  components: {
    'event-class-help-menu-modal': EventClassHelpMenuModal,
    'new-accident-incident-report': NewAccidentIncidentReport,
    'loading': AppLoading,
  },
  mixins:[vueAppMixin,permissionMixin, PaginationMixin],
  methods: {
    ...mapActions(['deleteReport','getReports', 'getVesselKeys','getFleetListKeys','getReportStatus','getCountryListKeys','getMngmtOfficeKeys','getManagementCompanyKeys']),
    clickImportAIR() {
      this.$refs.import_air.click();
    },

    async exportBlankTemplate() {
      this.toExport = !this.toExport;
      const vessel = this.vesselKeys.filter(vessel=>vessel.id === this.exportVesselId)[0];
      swal.fire({title: 'Downloading Template... Please wait'});
      swal.showLoading();

      let filename = 'Accident_Incident_Report' + '_' + (vessel.name).replace(' ', '_').toUpperCase() + '_TEMPLATE.xlsx';
      let mime_type = 'application/vnd.ms-excel';
      let file = await ReportService.downloadAIReportTemplate(vessel.id)
      let url = window.URL.createObjectURL(new Blob([file.data],{type:mime_type}));
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);

      swal.close();
      document.body.appendChild(link);
      link.click();

      this.exportVesselId = null;
    },

    async submitImportAIR() {
      swal.fire({
        'title': `Importing Accident Incident<br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })

      swal.showLoading()
      const file = this.$refs.import_air.files[0];
      let formData = new FormData();

      formData.append('file', file)

      const import_air = await ReportService.importAir(formData)
      swal.close();

      if (!import_air.hasOwnProperty('errors')) {
        const ok = await AlertService.successAlert(`New accident incident has been created`, 'IMPORT ACCIDENT INCIDENT');
        const accident_incident_id = import_air.data.id;

        if (ok && accident_incident_id) {
          setTimeout(() => this.$router.push({name: 'AccidentIncidentReport', params: {id: accident_incident_id}}), 3000);
        }
      } else {
        let error_message = import_air.message;
        if (Object.keys(import_air.errors).length) {
          error_message += '<br><br><ol style="text-align: left; font-size: 0.9em">';
          Object.keys(import_air.errors).forEach(key => {
            let err_msg = import_air.errors[key];
            let err_msg_match = err_msg.match(new RegExp("AIID-(.*)-AIID"));
            err_msg = err_msg_match ? err_msg.replace(err_msg_match[0], (this.$router.resolve({name: 'AccidentIncidentReport', params: {id: err_msg_match[1]}})).href) : err_msg;
            error_message += '<li class="e-text-red"><small>* ' + err_msg + '</small></li>';
          });
          error_message += '</ol>';
        }

        await AlertService.errorAlert(error_message, 'IMPORTING DATA')
      }

      this.$refs.import_air.value = null
    },

    async showReport (reportId) {
      await this.$router.push(
        {
          name: 'AccidentIncidentReport',
          params: {
            id: reportId,
          },
        },

        ).catch(() => {})
    },
    async showReportNewTab (reportId) {
      const route= this.$router.resolve(
        {
          name: 'AccidentIncidentReport',
          params: {
            id: reportId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async deleteSelected(reportId){
      if(await this.confirmDeleteAlert()){
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (inputValue)=> {
            let response = await this.deleteReport({reportId: reportId, password: inputValue});
            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors.password.join(','));
              }
            }
          }
        })
          .then(async (result)=>{
            if(result.isConfirmed){
               this.successAlert('Report Deleted Successfully','DELETE ACCIDENT/INCIDENT REPORT');
            }
        });
       // let response = await this.deleteReport(reportId);
       //  if(response) this.successAlert('Report Deleted Successfully','DELETE ACCIDENT/INCIDENT REPORT');
      }
    },
    setPage(page) {
      localStorage.setItem('accidentIncidentPage',page)
      this.pagination.currentPage = page;
      this.filters.page = page
      this.getReports(this.filters)
    },
    initializeWatcher(){
      this.$watch(()=>this.$route.query,async (present,previous)=>{
        this.filters.page=1
        this.filters.search=present?.search
        await this.getReports(this.filters)
      });
    },
  },
  async created() {
    this.loading = true
    await this.getVesselKeys()
    this.initializeWatcher()
    this.filters.page=1;
    if(this.$route.query.search){
      this.filters.search=this.$route.query.search
    }
    await this.getFleetListKeys();
    await this.getReportStatus();
    await this.getMngmtOfficeKeys();
    await this.getCountryListKeys();
    await this.getManagementCompanyKeys();
    await this.getReports(this.filters)
    this.loading = false
  },
  computed: {
    ...mapGetters(['reports','isLoading', 'vesselKeys','fleetKeys','mngmntOfficeKeys','countryKeys','reportStatusKeys','mngmtCompanyKeys']),
    finalizedReports() {
      let newReports = [];
      this.reports.forEach((report) => {
        let newReportCondition = (report.report_status_id === 1) && (this.user.roles_permissions.roles[0] !== 'instructor' || (this.user.roles_permissions.roles[0] === 'instructor' && report.is_confidential_report === true));
        let otherReportCondition = report.report_status_id !== 1;

        if (newReportCondition === true || otherReportCondition) {
          newReports.push(report);
        }
      });
      return newReports;
    }
  },
  watch: {
    async 'filters.count'(count) {
      this.filters.count = count
      this.filters.page = 1
      this.loading = true
      await this.getReports(this.filters)
      this.loading = false
    },
    async 'filters.flag_id'(flag_id) {
      this.filters.flag_id = flag_id
      this.filters.page = 1
      this.loading = true
      await this.getReports(this.filters)
      this.loading = false
    },
    async 'filters.fleet_id'(fleet_id) {
      this.filters.fleet_id = fleet_id
      this.filters.page = 1
      this.loading = true
      await this.getReports(this.filters)
      this.loading = false
    },
    async 'filters.office_id'(office_id) {
      this.filters.office_id = office_id
      this.filters.page = 1
      this.loading = true
      await this.getReports(this.filters)
      this.loading = false
    },
    async 'filters.status_id'(status_id) {
      this.filters.status_id = status_id
      this.filters.page = 1
      this.loading = true
      await this.getReports(this.filters)
      this.loading = false
    },
    async 'filters.company_id'(company_id) {
      this.filters.company_id = company_id
      this.filters.page = 1
      this.loading = true
      await this.getReports(this.filters)
      this.loading = false
    }
  }
}
</script>

<style scoped>
  table tr td{
    font-size: 10px;
  }
</style>
